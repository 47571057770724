import gql from 'graphql-tag';

export const CREATE_UE = gql`
mutation createUsuarioEmpresa($user: ID!, $empresa: ID $guardia: ID $empleado: ID $chofer: ID){
  createUsuarioEmpresa(input:{user: $user empresa: $empresa guardia: $guardia empleado: $empleado chofer: $chofer})
  {
    usuarioEmpresa{
      id
      user{
      id
      username
    }
    empresa{
      id
      nombre
    }
    guardia{
      id
      nombre
    }
    empleado{
      id
      numero
      nombre
    }
    chofer{
      id
      nombre
    }
  }
  }
}
`;

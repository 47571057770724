import gql from 'graphql-tag';

export const ALL_EMPLEADOS = gql`
  query allEmpleados {
    allEmpleados
    {
        edges{
        node{
            id
            numero
            nombre
            apellidoPaterno
            apellidoMaterno
            celular
    
        }
    }
    }
}
`;

export const FILTER_EMPLEADOS = gql`
  query allEmpleados($range: String $in: String) {
    allEmpleados(numero_Range: $range numero_In: $in){
      edges{
        node{
          id
          nombre
          apellidoPaterno
          apellidoMaterno
        }
      }
    }
}
`;

export const EMPLEADO = gql`
  query empleado($id: ID!) {
    empleado(id:$id) {
      id
      numero
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
  }
}
`;



import gql from 'graphql-tag';

export const ALL_PEDIDOS = gql`
  query allTipoPedidos {
    allTipoPedidos{
      edges{
        node{
          id
          nombre
          origen
          destino
        }
      }
    }
}
`;

export const TIPO_PEDIDO = gql`
  query tipoPedido($id: ID!) {
    tipoPedido(id:$id) {
      id
      nombre
      origen
      destino
  }
}
`;



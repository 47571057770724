import gql from 'graphql-tag';

export const ALL_PLANTAS = gql`
  query allPlantas {
    allPlantas
      {
       edges{
        node{
          id
          nombre
        }
      }
  }
}
`;

export const PLANTA = gql`
  query planta($id: ID!) {
    planta(id:$id) {
      id
      nombre
  }
}
`;

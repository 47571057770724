<template>
  <v-dialog
      v-model="showForm"
      max-width="400px"
      transition="dialog-top-transition"
      persistent
  >
    <v-card>
      <v-toolbar fixed dark color="primary">
        <v-toolbar-title>
          <h4 class="title">{{title}}</h4>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
            icon
            dark
            @click="closed"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form>
        <v-card-text>
          <div class="md-layout">
            <div >
              <v-autocomplete
                  v-model="uempresa.user"
                  :items="users"
                  item-text="username"
                  item-value="id"
                  label="Seleccionar Usuario"
                  chips
                  clearable
                  deletable-chips
                  small-chips
                  @change="getRoll"
                  hint="Usuario"
                  persistent-hint

              >
              </v-autocomplete>
              <v-autocomplete
                  v-model="uempresa.chofer"
                  :items="choferes"
                  item-text="nombre"
                  item-value="id"
                  label="Seleccionar Chofer"
                  chips
                  clearable
                  deletable-chips
                  small-chips
                  hint="Chofer"
                  persistent-hint

              >
              </v-autocomplete>
              <v-autocomplete
                  v-model="uempresa.guardia"
                  :items="guardias"
                  item-text="nombre"
                  item-value="id"
                  label="Seleccionar Guardia"
                  chips
                  clearable
                  deletable-chips
                  small-chips
                  hint="Guardia"
                  persistent-hint

              >
              </v-autocomplete>
              <v-autocomplete
                  v-model="uempresa.empresa"
                  :items="empresas"
                  item-text="nombre"
                  item-value="id"
                  label="Selecciona la Empresa"
                  chips
                  hint="Empresa"
                  persistent-hint
              ></v-autocomplete>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="terciary" dark @click="closed">
            Cancelar
          </v-btn>
          <v-btn v-if="title==='Actualizar Usuario Empresa'" color="secondary" @click="update" :loading="loading">
            Actualizar
          </v-btn>
          <v-btn v-else color="secondary" @click="create" :loading="loading">
            Crear
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AsignacionForm",
  props: {
    title: String,
    uempresa: {},
    showForm: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['users','guardias', 'choferes', 'empresas'])
  },
  data: ()=>({
    loading: false,
  }),
  methods: {
    async create() {
      this.loading= true;
      await this.$store.dispatch('createUsuarioEmpresa', this.uempresa);
      this.loading= false;
      this.closed();
    },
    async update() {
      this.loading= true;
      await this.$store.dispatch('updateChofer', this.uempresa);
      this.loading= false;
      this.closed();
    },
    getRol(user) {
       console.log(user)
    },
    closed() {
      this.$emit('close');
    },
  },
}
</script>

<style scoped>

</style>

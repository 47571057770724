import gql from 'graphql-tag';

export const ALL_TAXIS = gql`
  query allTaxis {
    allTaxis
      {
       edges{
        node{
          id
          modelo
          marca
          matricula
          cantPasajeros
        }
      }
  }
}
`;

export const TAXI = gql`
  query taxi($id: ID!) {
    taxi(id:$id) {
      id
      modelo
      marca
      matricula
      cantPasajeros
  }
}
`;

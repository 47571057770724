import gql from 'graphql-tag';

export const ALL_PEDIDOS = gql`
  query allPedidos {
    allPedidos{
      edges{
        node{
          id
          fecha
          hora
          estado
          tipoPedido{
            id
            nombre
            origen
            destino
          }
        }
      }
    }
}
`;


export const PEDIDO = gql`
  query pedido($id: ID!) {
    pedido(id:$id) {
      id
          fecha
          hora
          tipoPedido{
            id
            nombre
            origen
            destino
          }
  }
}
`;



import gql from 'graphql-tag';

export const CREATE_PLANTA = gql`
mutation CreatePlanta($domicilio: ID! $nombre: String! $empresa: ID!){
 createPlanta(input: { domicilio:$domicilio nombre:$nombre empresa:$empresa}) {
  planta{ 
   id
   nombre
  }
}
}
`;

export const UPDATE_PLANTA = gql`
mutation UpdatePlanta($id: ID! $domicilio: ID! $nombre: String! $empresa: ID!){
 updatePlanta(id:$id input: { domicilio:$domicilio, nombre:$nombre empresa:$empresa}) {
  planta{
    id
    nombre
  }
}
}
`;

export const DELETE_PLANTA = gql`
mutation DeletePlanta($id: ID!){
deletePlanta(id:$id) {
  found
}
}
`;

<template>
  <div id="app">
    <v-card>
      <notifications/>
    </v-card>
    <v-container fluid pa-0>
      <v-row align="center" justify="center" style="height: 100vh" dense>
        <v-card max-width="400" width="375" elevation="24" color="#BBDEFB" class="justify-center transparente">
          <v-card-title  color="blue darken-4" class="justify-center transparente">
            Bienvenido
          </v-card-title>
          <v-card-text>
            <v-form ref="loginForm" v-model="isValidForm">
              <v-text-field
                  class="rounded-tl-xl rounded-br-xl"
                  placeholder="Usuario"
                  color="black"
                  solo

                  filled
                  background-color="white"
                  prepend-inner-icon="mdi-account"
                  v-model="authDetails.username"
                  :rules="loginFieldRules"
              />
              <v-text-field
                  placeholder="Contraseña"
                  class="rounded-tl-xl rounded-br-xl"
                  color="black"
                  solo

                  filled
                  background-color="white"
                  v-model="authDetails.password"
                  :rules="passwordFieldRules"
                  :type="marker ? 'password' : 'text'"
                  prepend-inner-icon="mdi-key"
                  :append-icon="marker ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="toggleMarker"
                  @keydown="passwordFieldErrors = []"
                  @keypress.enter="loginUser"
              />
              <div class="text-center">
                <v-btn
                    class="ma-2 rounded-tl-xl rounded-br-xl"
                    color="blue darken-4"
                    dark
                    @click="loginUser"
                >
                  <v-icon
                      dark
                      left
                  >
                    mdi-arrow-right
                  </v-icon>Entrar
                </v-btn>
              </div>
            </v-form>
            <span class="pl-n10"><a>¿Olvidó su contraseña?</a></span>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Login',
  data: () => ({
    loading: false,
    marker: true,
    width:300,
    authDetails: {
      username: '',
      password: '',
    },

    // Campo de contraseña o texto
    showText: false,

    // Formulario
    isValidForm: true,
    loginFieldRules: [(v) => !!v || 'Debe proporcionar un nombre de usuario.'],
    loginFieldErrors: [],
    passwordFieldRules: [(v) => !!v || 'Debe proporcionar una contraseña.'],
    passwordFieldErrors: [],
  }),
  methods: {
    toggleMarker() {
      this.marker = !this.marker
    },
    async loginUser() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        // eslint-disable-next-line no-unused-vars
        await this.$store.dispatch('login', this.authDetails);
        this.loading = false;
        if (this.$store.getters.authStatus) {
          this.$notify({
            message:
              `${this.$store.getters.user.firstName || this.$store.getters.user.username}
               Welcome abord`,
            icon: 'lock_open_variant_outline',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          });
          this.$router.push('/dashboard');
        } else {
          this.authDetails.password = '';
          this.$notify({
            message:
              'Username or Password Wrong!!! Try again.',
            icon: 'add_alert',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger',
          });
          /* errors??.nonFieldErrors.forEach((error) => {
            this.loginFieldErrors.push(error.message);
            this.passwordFieldErrors.push(error.message);
            console.log(`Errors: ${error.message}`);
          }); */
        }
      }
    },
  },
};
</script>

<style scoped>
.transparente {
  opacity: 0.85;
}
#app {
  background: url("../assets/fondo_cards.png");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100% ;
}
</style>

import Login from "../views/Login";
// eslint-disable-next-line no-unused-vars
import Dashboard from "../views/Dashboard";
// eslint-disable-next-line no-unused-vars
import DashboardLayout from "../views/layouts/DashboardLayout";
import UserProfile from "../views/user/UserProfile";
import UserList from "../views/user/UserList";
import AsignacionList from "../views/asignacion/AsignacionList";
import CountryList from "../views/country/CountryList";
import EstadoList from "../views/estado/EstadoList";
import MunicipioList from "../views/municipio/MunicipioList";
import ColoniaList from "../views/colonia/ColoniaList";
import Groups from "../views/groups/Groups";
import Empresa from "../views/empresa/Empresa";
import TaxiList from "../views/taxi/TaxiList";
import ChoferList from "../views/chofer/ChoferList";
import DepartamentoList from "../views/departamento/DepartamentoList";
import PlantaList from "../views/planta/PlantaList";
import DireccionList from "../views/direcciones/Direccionlist";
import PedidoList from "../views/pedido/PedidoList";
import GuardiaList from "../views/guardia/GuardiaList";
import HorarioList from "../views/horario/HorarioList";
import LocacionList from "../views/locacion/LocacionList";
import CondicionList from "../views/condicion/CondicionList";
import OpcionList from "../views/opcion/OpcionList";
import EmpresaCondicionList from "../views/empresaCondicion/EmpresaCondicionList";
import Calendario from "../views/calendario/Calendario";

const routes = [
    {
        path: '',
        name: 'Login',
        component: Login,
        meta: { hideForAuth: true },
    },
    {
        path: '/',
        component: DashboardLayout,
        redirect: 'dashboard',
        children: [
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: Dashboard,
                meta: { requiresAuth: true, },
            },
            {
                path: '/user',
                name: 'User Profile',
                component: UserProfile,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/users',
                name: 'User List',
                component: UserList,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/asignacion',
                name: 'Asignaciones',
                component: AsignacionList,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/groups',
                name: 'Group List',
                component: Groups,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/country',
                name: 'Country List',
                component: CountryList,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/direccion',
                name: 'Direcciones',
                component: DireccionList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/estado',
                name: 'Estados',
                component: EstadoList,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/municipio',
                name: 'Municipios',
                component: MunicipioList,
                meta: { requiresAuth: true },
            },
            {
                path: '/colonia',
                name: 'Colonias',
                component: ColoniaList,
                meta: { requiresAuth: true },
            },
            {
                path: '/empresa',
                name: 'Empresas',
                component: Empresa,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/taxi',
                name: 'Taxis',
                component: TaxiList,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/chofer',
                name: 'Choferes',
                component: ChoferList,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/departamento',
                name: 'Departamentos',
                component: DepartamentoList,
                meta: { requiresAuth: true,  },
            },
            {
                path: '/planta',
                name: 'Plantas',
                component: PlantaList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/pedido',
                name: 'Pedidos',
                component: PedidoList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/guardia',
                name: 'Guardias',
                component: GuardiaList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/horario',
                name: 'Horarios',
                component: HorarioList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/locacion',
                name: 'Locaciones',
                component: LocacionList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/condicion',
                name: 'Condiciones',
                component: CondicionList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/opcion',
                name: 'Opciones',
                component: OpcionList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/empresa-condicion',
                name: 'Empresa-Condiciones',
                component: EmpresaCondicionList,
                meta: { requiresAuth: true, },
            },
            {
                path: '/planificador',
                name: 'Planificador',
                component: Calendario,
                meta: { requiresAuth: true, },
            },
        ],
    },
]

export default routes;

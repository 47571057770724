import gql from 'graphql-tag';

export const CREATE_GUARDIA= gql`
mutation CreateGuardia($nombre: String! $apellidoPaterno: String!
 $apellidoMaterno: String! $celular: String! $empresa: ID! $domicilio: ID){
 createGuardia(input: { nombre:$nombre apellidoPaterno:$apellidoPaterno 
 apellidoMaterno:$apellidoMaterno celular:$celular empresa:$empresa domicilio: $domicilio}) {
  guardia{ 
   id
   nombre
   apellidoPaterno
   apellidoMaterno
   celular
   empresa{
     id
     nombre
   }
   domicilio{
        id
        nombre
        numero
      }
  }
}
}
`;

export const UPDATE_GUARDIA= gql`
mutation UpdateGuardia($id: ID! $nombre: String! $apellidoPaterno: String!
 $apellidoMaterno: String! $celular: String! $empresa: ID! $domicilio: ID){
 updateGuardia(id: $id input: { nombre:$nombre apellidoPaterno:$apellidoPaterno 
 apellidoMaterno:$apellidoMaterno celular:$celular empresa:$empresa domicilio: $domicilio}) {
  guardia{ 
   id
   nombre
   apellidoPaterno
   apellidoMaterno
   celular
   empresa{
     id
     nombre
   }
   domicilio{
        id
        nombre
        numero
      }
  }
}
}
`;

export const DELETE_GUARDIA = gql`
mutation DeleteGuardia($id: ID!){
deleteGuardia(id:$id) {
  found
}
}
`;

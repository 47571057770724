import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_DEPARTAMENTOS } from '../graphql/query/departamentoQueries';
import { CREATE_DEPARTAMENTO, UPDATE_DEPARTAMENTO, DELETE_DEPARTAMENTO } from '../graphql/mutation/departamentoMutation';


Vue.use(Vuex);

const state = () => ({
    departamentos: [],
    pagination: [],
});

const getters = {
    departamentos: state => state.departamentos,
};

const mutations = {
    SET_DEPARTAMENTOS: (state, payload) => state.departamentos = payload,
    ADD_DEPARTAMENTO: (state, payload) => state.departamentos.push(payload),
    DELETE_DEPARTAMENTO: (state, payload) => state.departamentos = state.departamentos.filter(el => el.id !== payload.id),
    UPDATE_DEPARTAMENTO: (state, payload) => state.departamentos = state.departamentos.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allDepartamentos({ commit } ) {
        try {
            const { data: { allDepartamentos: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_DEPARTAMENTOS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_DEPARTAMENTOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createDepartamento({ commit }, payload ) {
        try {
            const { data: { createDepartamento: { departamento } } }  = await apolloClient
                .mutate({ mutation: CREATE_DEPARTAMENTO, variables: { ...payload }  });
            commit('ADD_DEPARTAMENTO', departamento);
        } catch (e) {
            console.log(e);
        }
    },
    async updateDepartamento({ commit }, payload ) {
        try {
            const { data: { updateDepartamento: { departamento } } }  = await apolloClient
                .mutate({ mutation: UPDATE_DEPARTAMENTO, variables: { ...payload }  });
            commit('UPDATE_DEPARTAMENTO', departamento);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteDepartamento({ commit }, payload ) {
        try {
            const { data: { deleteDepartamento: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_DEPARTAMENTO, variables: { ...payload }  });
            if (found){
                commit('DELETE_DEPARTAMENTO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

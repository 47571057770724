import Vue from 'vue'
import Vuex from 'vuex'
import AuthStore from './authStore';
import UserStore from './userStore';
import CountryStore from './countryStore';
import EstadoStore from './estadoStore';
import MunicipioStore from './municipioStore';
import ColoniaStore from './coloniaStore';
import DomicilioStore from './domicilioStore';
import EmpresaStore from './empresaStore';
import DepartamentoStore from './departamentoStore';
import PlantaStore from './plantaStore';
import EmpleadoStore from './empleadoStore';
import GuardiaStore from './guardiaStore';
import TaxiStore from './taxiStore';
import ChoferStore from './choferStore';
import GroupStore from "./groupStore";
import PedidoStore from "./pedidoStore";
import TipoPedidoStore from "./tipoPedidoStore";
import HorarioStore from "./horarioStore";
import LocacionStore from "./locacionStore";
import CondicionStore from "./condicionStore";
import OpcionStore from "./opcionStore";
import EmpresaCondicionStore from './empresaCondicionStore';
import UsuarioEmpresaStore from './usuarioEmpresaStore';
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    AuthStore,
    GroupStore,
    UserStore,
    CountryStore,
    EstadoStore,
    MunicipioStore,
    ColoniaStore,
    DomicilioStore,
    EmpresaStore,
    DepartamentoStore,
    PlantaStore,
    EmpleadoStore,
    TaxiStore,
    ChoferStore,
    PedidoStore,
    TipoPedidoStore,
    GuardiaStore,
    HorarioStore,
    LocacionStore,
    CondicionStore,
    OpcionStore,
    EmpresaCondicionStore,
    UsuarioEmpresaStore,
  },
})

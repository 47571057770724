import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { ALL_PEDIDOS } from '../graphql/query/tipoPedidoQueries';
import { CREATE_PEDIDO, UPDATE_PEDIDO, DELETE_PEDIDO } from '../graphql/mutation/tipoPedidoMutation';


Vue.use(Vuex);

const state = () => ({
    tipoPedidos: [],
    pagination: [],
});

const getters = {
    tipoPedidos: state => state.tipoPedidos,
};

const mutations = {
    SET_TIPOPEDIDOS: (state, payload) => state.tipoPedidos = payload,
    ADD_TIPOPEDIDO: (state, payload) => state.tipoPedidos.push(payload),
    DELETE_TIPOPEDIDO: (state, payload) => state.tipoPedidos = state.tipoPedidos.filter(el => el.id !== payload.id),
    UPDATE_TIPOPEDIDO: (state, payload) => state.tipoPedidos = state.tipoPedidos.map(el => (el.id === payload.id ? payload : el)),
};

const actions = {
    async allTipoPedidos({ commit } ) {
        try {
            const { data: { allTipoPedidos: { edges } } }  = await apolloClient
                .mutate({ mutation: ALL_PEDIDOS });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_TIPOPEDIDOS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createTipoPedido({ commit }, payload ) {
        try {
            const { data: { createTipoPedido: { tipoPedido } } }  = await apolloClient
                .mutate({ mutation: CREATE_PEDIDO, variables: { ...payload }  });
            commit('ADD_TIPOPEDIDO', tipoPedido);
        } catch (e) {
            console.log(e);
        }
    },
    async updateTipoPedido({ commit }, payload ) {
        try {
            const { data: { updateTipoPedido: { tipoPedido } } }  = await apolloClient
                .mutate({ mutation: UPDATE_PEDIDO, variables: { ...payload }  });
            commit('UPDATE_TIPOPEDIDO', tipoPedido);
        } catch (e) {
            console.log(e);
        }
    },
    async deleteTipoPedido({ commit }, payload ) {
        try {
            const { data: { deleteTipoPedido: { found } } }  = await apolloClient
                .mutate({ mutation: DELETE_PEDIDO, variables: { ...payload }  });
            if (found){
                commit('DELETE_TIPOPEDIDO', payload);
            }

        } catch (e) {
            console.log(e);
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

import gql from 'graphql-tag';

export const ALL_DEPARTAMENTOS = gql`
  query allDepartamentos {
    allDepartamentos
      {
       edges{
        node{
          id
          nombre
        }
      }
  }
}
`;

export const DEPARTAMENTO = gql`
  query departamento($id: ID!) {
    departamento(id:$id) {
      id
      nombre
  }
}
`;

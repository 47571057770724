import Vue from 'vue';
import Vuex from 'vuex';
import { apolloClient } from '../vue-apollo';
import { All_UEMPRESA } from '../graphql/query/usuarioEmpresaQueries';
import {CREATE_UE }
    from '../graphql/mutation/usuarioEmpresaMutation';

Vue.use(Vuex);

const state = () => ({
    uempresas: [],
});

const getters = {
    uempresas: (state) => state.uempresas,
};
const mutations = {
    SET_UEMPRESAS: (state, payload) => state.uempresas = payload,
    ADD_UEMPRESA: (state, payload) => state.uempresas.push(payload),
    UPDATE_UEMPRESA: (state, payload) => state.uempresas = state.uempresas.map(el => (el.id === payload.id ? payload : el)),
    DELETE_UEMPRESA:(state, payload) => state.uempresas = state.uempresas.filter(el => el.id !== payload.id),
};
const actions = {
    async allUsuarioEmpresa({ commit } ) {
        try {
            const { data: { allUsuarioEmpresa: { edges } } }  = await apolloClient
                .mutate({ mutation: All_UEMPRESA });
            const nodes = edges.map((d)=> d.node);
            await commit('SET_UEMPRESAS', nodes);
        } catch (e) {
            console.log(e);
        }
    },

    async createUsuarioEmpresa({commit}, payload) {
        try {
            const { data: { createUsuarioEmpresa: {usuarioEmpresa}} } = await apolloClient
                .mutate({mutation: CREATE_UE, variables: {...payload}});

            commit('ADD_UEMPRESA', usuarioEmpresa)
        }catch (e) {
            console.log(e);
        }
    },

    // eslint-disable-next-line no-unused-vars
    async updateUsuarioEmpresa({ commit }, payload) {
        // eslint-disable-next-line no-unused-vars
        const { data: { updateAccount: { success } } } = await apolloClient.mutate(
            { mutation: payload, variables: { ...payload } },
        );

    },
    async deleteUser({commit}, payload) {
        try {
            const { data: { deleteAccountByAdmin: {found} } } = await apolloClient
                .mutate({mutation: payload, variables: {...payload}});
            console.log(found)
            commit('DELETE_USER', payload)


        }catch (e) {
            console.log(e);
        }
    },

};
export default {
    state,
    getters,
    actions,
    mutations,
};

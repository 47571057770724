import gql from 'graphql-tag';

export const CREATE_PEDIDO = gql`
mutation CreateTipoPedido($nombre: String! $origen: String! $destino: String! ){
 createTipoPedido(input: { nombre:$nombre origen:$origen destino:$destino }) {
  tipoPedido{ 
    id
    nombre
    origen
    destino
  }
}
}
`;

export const UPDATE_PEDIDO = gql`
mutation UpdatePedido($id: ID! $nombre: String! $origen: String! $destino: String!){
 updatePedido(id:$id input: { nombre:$nombre origen:$origen destino:$destino}) {
  pedido{ 
    id
    nombre
    origen
    destino 
  }
}
}
`;

export const DELETE_PEDIDO = gql`
mutation DeleteTipoPedido($id: ID!){
deleteTipoPedido(id:$id) {
  found
}
}
`;

<template>
<calendario2 :model="pedidos"></calendario2>
</template>

<script>
import Calendario2 from '../../components/Calendario/Calendario2'
import {mapGetters} from "vuex";
export default {
  name: "Calendario",
  components: {Calendario2},
  computed: {
    ...mapGetters(['pedidos']),
  },
  mounted () {
    this.$store.dispatch('allPedidos');
  },
}
</script>

<style scoped>

</style>

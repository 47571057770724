import gql from 'graphql-tag';

export const ALL_CHOFERES = gql`
  query allChoferes {
    allChoferes
    {
        edges{
        node{
            id
            nombre
            apellidoPaterno
            apellidoMaterno
            celular
    
        }
    }
    }
}
`;

export const CHOFER = gql`
  query chofer($id: ID!) {
    chofer(id:$id) {
      id
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
  }
}
`;



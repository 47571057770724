import gql from 'graphql-tag';

export const CREATE_CHOFER = gql`
mutation CreateChofer($nombre: String! $apellido_paterno: String! $celular: String! ){
 createChofer(input: { nombre:$nombre apellido_paterno:$apellido_paterno celular:$celular }) {
  chofer{ 
      id
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
  }
}
}
`;

export const UPDATE_CHOFER = gql`
mutation UpdateChofer($id: ID! $nombre: String! $apellido_paterno: String! $celular: String!){
 updateChofer(id:$id input: { nombre:$nombre apellido_paterno:$apellido_paterno celular:$celular}) {
  chofer{
      id
      nombre
      apellidoPaterno
      apellidoMaterno
      celular
  }
}
}
`;

export const DELETE_CHOFER = gql`
mutation DeleteChofer($id: ID!){
deleteChofer(id:$id) {
  found
}
}
`;
